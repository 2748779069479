import './tourGuideCustomStyles.css';
import { TourGuideClient } from '@sjmc11/tourguidejs/src/Tour';
import type { TourGuideOptions } from '@sjmc11/tourguidejs/src/core/options';
import '@sjmc11/tourguidejs/src/scss/tour.scss';

class NullableTourGuideClient extends TourGuideClient {
    // @ts-expect-error don't call super() as it uses document and in Electron it is not available
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    constructor() {}
}

const tg = APP_ELECTRON
    ? new NullableTourGuideClient()
    : new TourGuideClient({
          progressBar: '#01cfcf',
          showStepDots: false,
          nextLabel: `Next`,
          prevLabel: `Prev`,
          dialogClass: 'tg-dialog-custom',
      } as TourGuideOptions);

export { tg };
