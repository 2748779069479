export type UpdateOrderBlotterAccountAction = {
    type: 'updateOrderBlotterAccount';
    tabId: string;
    accountIds: string[] | null;
};
export const updateOrderBlotterAccount = (
    tabId: string,
    accountIds: string[] | null,
): UpdateOrderBlotterAccountAction => ({
    tabId,
    accountIds,
    type: 'updateOrderBlotterAccount',
});

export type UpdateOrderBlotterSymbolAction = { type: 'updateOrderBlotterSymbol'; tabId: string; symbol: string };
export const updateOrderBlotterSymbol = (tabId: string, symbol: string): UpdateOrderBlotterSymbolAction => ({
    tabId,
    symbol,
    type: 'updateOrderBlotterSymbol',
});

export type UserSelectedNewOrderBlotterSymbolAction = {
    type: 'userSelectedNewOrderBlotterSymbol';
    symbol: string;
    tabId: string;
};

export const userSelectedNewOrderBlotterSymbol = (
    tabId: string,
    symbol: string,
): UserSelectedNewOrderBlotterSymbolAction => ({
    symbol,
    tabId,
    type: 'userSelectedNewOrderBlotterSymbol',
});

export type OrderBlotterActions =
    | UpdateOrderBlotterSymbolAction
    | UserSelectedNewOrderBlotterSymbolAction
    | UpdateOrderBlotterAccountAction;
