import type { Instant } from '@js-joda/core';
import type { Idea, IfThenResearchPlan } from '@thinkalpha/platform-ws-client/contracts/ideas/index.js';
import type { IndicatorImportViewModel } from 'src/contracts/dictionary-view-model';
import type { Strategy } from 'src/contracts/strategy';

export type UserRanChangesAction = {
    type: 'userRanChanges';
    tabId: string;
    strategy: Strategy<IndicatorImportViewModel>;
};
export const userRanChanges = (tabId: string, strategy: Strategy<IndicatorImportViewModel>): UserRanChangesAction => ({
    tabId,
    strategy,
    type: 'userRanChanges',
});

export type setSearchAlphaIdea = {
    type: 'setSearchAlphaIdea';
    tabId: string;
    strategy: Strategy<IndicatorImportViewModel>;
};
export const setSearchAlphaIdea = (
    tabId: string,
    strategy: Strategy<IndicatorImportViewModel>,
): setSearchAlphaIdea => ({
    strategy,
    tabId,
    type: 'setSearchAlphaIdea',
});

/* New search alpha! */
export type SetSearchAlphaDisplayingSearchAction = {
    type: 'setSearchAlphaDisplayingSearch';
    tabId: string;
    isShowing: boolean;
};
export type SetSearchAlphaDisplayingResultsAction = {
    type: 'setSearchAlphaDisplayingResults';
    tabId: string;
    isShowing: boolean;
};
export type SetSearchAlphaDisplayingIdeasAction = {
    type: 'setSearchAlphaDisplayingIdeas';
    tabId: string;
    isShowing: boolean;
};
export type SetSearchAlphaLastSubmitAction = {
    type: 'setSearchAlphaLastSubmit';
    tabId: string;
    lastSubmit: Instant | undefined;
};
export type SetSearchAlphaStrategyAction = {
    type: 'setSearchAlphaStrategy';
    tabId: string;
    idea: Idea & { plan: IfThenResearchPlan };
};
export type SetSearchAlphaUniverseAction = {
    type: 'setSearchAlphaUniverse';
    tabId: string;
    universeId: string | null;
};
export type StoreSearchAlphaSplitPercentageAction = {
    type: 'storeSearchAlphaSplitPercentage';
    tabId: string;
    splitPercentage: number;
};

export const setSearchAlphaDisplayingSearch = (
    tabId: string,
    isShowing: boolean,
): SetSearchAlphaDisplayingSearchAction => ({
    tabId,
    isShowing,
    type: 'setSearchAlphaDisplayingSearch',
});
export const setSearchAlphaDisplayingResults = (
    tabId: string,
    isShowing: boolean,
): SetSearchAlphaDisplayingResultsAction => ({
    tabId,
    isShowing,
    type: 'setSearchAlphaDisplayingResults',
});
export const setSearchAlphaDisplayingIdeas = (
    tabId: string,
    isShowing: boolean,
): SetSearchAlphaDisplayingIdeasAction => ({
    tabId,
    isShowing,
    type: 'setSearchAlphaDisplayingIdeas',
});
export const setSearchAlphaStrategy = (
    tabId: string,
    idea: Idea & { plan: IfThenResearchPlan },
): SetSearchAlphaStrategyAction => ({
    tabId,
    idea,
    type: 'setSearchAlphaStrategy',
});
export const setSearchAlphaUniverse = (tabId: string, universeId: string | null): SetSearchAlphaUniverseAction => ({
    tabId,
    universeId,
    type: 'setSearchAlphaUniverse',
});
export const storeSearchAlphaSplitPercentage = (
    tabId: string,
    splitPercentage: number,
): StoreSearchAlphaSplitPercentageAction => ({
    tabId,
    splitPercentage,
    type: 'storeSearchAlphaSplitPercentage',
});

export type SearchAlphaActions =
    | UserRanChangesAction
    | setSearchAlphaIdea
    | SetSearchAlphaDisplayingSearchAction
    | SetSearchAlphaDisplayingResultsAction
    | SetSearchAlphaDisplayingIdeasAction
    | SetSearchAlphaLastSubmitAction
    | SetSearchAlphaStrategyAction
    | SetSearchAlphaUniverseAction
    | StoreSearchAlphaSplitPercentageAction;
